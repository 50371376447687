<template>

<a :href="item.url" target="_blank" class="card">
	
	<div class="card-image" v-bgimage="item.thumb"></div>

	<div class="card-text">

		<div class="card-text-name">{{ item.name }}</div>
		<div class="card-text-type">{{ item.tags.join(', ') }}</div>

	</div>

</a>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.card {
	width: calc(50% - 20px);
	border-bottom: 2px solid #eee;
	padding-bottom: 20px;
	height: 129px;
	margin-right: 40px;
	display: flex;
}

.is-mobile .card {
	width: 100%;
	margin-right: 0px;
}

.card:nth-child(2n) {
	margin-right: 0px;
}

.card-image {
	width: 82px;
	height: 108px;
	flex-shrink: 0;
	background-color: #eee;
	background-size: cover;
	background-position: 50% 50%;
}

.card-text {
	padding-left: 20px;
	height: 108px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
}

.card-text-name {
	color: #1a234c;
	font-size: 18px;
	font-weight: bold;
	line-height: 24px;
}

.is-mobile ,card-text-name {
	font-size: 14px;
	line-height: 20px;
}

.card-text-type {
	color: #343434;
	font-size: 14px;
	line-height: 30px;
	font-style: italic;
}

.is-mobile .card-text-type {
	font-size: 10px;
	line-height: 20px;
}

</style>
